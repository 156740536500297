import { ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PAR } from "../../../constants/room";

type RoomOptionIsRequiredForParticularParam0Type = {
  option: ReturnType<
    typeof import("../../../normalizers/room/parseData").default
  >["optionList"][number];
};

/**
 * Retourne vrai si l'option est requise pour les particuliers.
 */
const roomOptionIsRequiredForParticular = ({
  option,
}: RoomOptionIsRequiredForParticularParam0Type) =>
  option.required === ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PAR;

export default roomOptionIsRequiredForParticular;
