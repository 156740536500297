// dependencies
import React, { useState, useMemo, useRef } from "react";
import { StyleSheet, css } from "aphrodite";

// constants
import theming from "@gdf/resources/src/constants/theming";

const { useTheme } = theming;

const styles = StyleSheet.create({
  checkBox: {
    display: "flex",
    fontSize: "1rem",
    borderColor: "#cfdadd",
    borderWidth: "0.0625rem",
    borderStyle: "solid",
    borderRadius: "0.1875rem",
    position: "relative",
    paddingTop: "0.6875rem",
    paddingBottom: "0.6875rem",
    cursor: "pointer",
    ":after": {
      content: "''",
      position: "absolute",
      top: -1,
      right: -1,
      bottom: -1,
      left: -1,
      borderStyle: "solid",
      borderColor: "transparent",
      borderWidth: 2,
      borderRadius: "0.1875rem",
    },
  },
  checkBox__focus: {
    ":after": {
      borderColor: "#000",
    },
  },
  checkBox__noBorder: {
    border: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  checkBox__disabled: {
    cursor: "not-allowed",
  },
  required: {
    position: "absolute",
    right: "0.125rem",
    top: "0.0625rem",
    color: "#ff0000",
  },
  wrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  label: {
    paddingLeft: "0.625rem",
    paddingRight: "0.625rem",
    flex: 1,
    position: "relative",
    zIndex: 1,
  },
  input: {
    width: "1rem",
    height: "1rem",
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: "0.625rem",
    cursor: "pointer",
    ":focus": {
      outline: "none",
    },
  },
  input__noBorder: {
    marginLeft: 0,
  },
  input__disabled: {
    cursor: "not-allowed",
  },
});

type PropsType = {
  label: string;
  name: string;
  style?: object;
  id: string;
  checked: boolean;
  value: string;
  required?: boolean;
  disabled?: boolean;
  noBorder?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckBox: React.FunctionComponent<PropsType> = (props) => {
  const {
    name,
    label,
    id,
    disabled,
    style,
    value,
    checked,
    children,
    noBorder,
    onChange,
    ...otherProps
  } = props;

  const theme = useTheme();

  const $input = useRef<any>();

  const [focused, setFocused] = useState(false);

  const required = disabled ? false : props.required;

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      label: {
        fontFamily: theme.FONT_FAMILY,
      },
      checkBox__disabled: {
        borderColor: "#CFDADD",
        backgroundColor: "#CFDADD",
        color: "#000000",
        cursor: "not-allowed",
        ":hover": {
          borderColor: "#CFDADD",
          backgroundColor: "#CFDADD",
          color: "#000000",
        },
        ":active": {
          borderColor: "#CFDADD",
          backgroundColor: "#CFDADD",
          color: "#000000",
        },
      },
    });
  }, [theme]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (!disabled) {
      switch (event.key) {
        case " ": {
          event.preventDefault();
          $input.current.click();

          break;
        }
      }
    }
  };

  return (
    <label
      tabIndex={0}
      style={style}
      className={css(
        styles.checkBox,
        noBorder && styles.checkBox__noBorder,
        focused && styles.checkBox__focus,
        disabled &&
          styles.checkBox__disabled &&
          disabled &&
          dynamicStyles.checkBox__disabled
      )}
      htmlFor={id}
      title={label}
      aria-label={label}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={handleKeyDown}
    >
      <div className={css(styles.wrapper)}>
        <input
          {...otherProps}
          ref={$input}
          id={id}
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          className={css(
            styles.input,
            disabled && styles.input__disabled,
            noBorder && styles.input__noBorder
          )}
          disabled={disabled}
          tabIndex={-1}
          onChange={onChange}
        />

        <div className={css(styles.label, dynamicStyles.label)}>
          {children ? children : label}
        </div>
      </div>

      {required && <div className={css(styles.required)}>*</div>}
    </label>
  );
};

CheckBox.defaultProps = {
  required: false,
  disabled: false,
  noBorder: false,
};

export default CheckBox;
