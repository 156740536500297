import { ROOM_OPTION_REQUIRE_TYPE_VALUE_NOT_REQ } from "../../../constants/room";

type RoomOptionIsNotRequiredParam0Type = {
  option: ReturnType<
    typeof import("../../../normalizers/room/parseData").default
  >["optionList"][number];
};

/**
 * Retourne vrai si l'option est n'est pas requise (facultative).
 */
const roomOptionIsNotRequired = ({
  option,
}: RoomOptionIsNotRequiredParam0Type) =>
  option.required === ROOM_OPTION_REQUIRE_TYPE_VALUE_NOT_REQ;

export default roomOptionIsNotRequired;
