import { ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PRO } from "../../../constants/room";

type RoomOptionIsRequiredForProfessionalParam0Type = {
  option: ReturnType<
    typeof import("../../../normalizers/room/parseData").default
  >["optionList"][number];
};

/**
 * Retourne vrai si l'option est requise pour les professionnels.
 */
const roomOptionIsRequiredForProfessional = ({
  option,
}: RoomOptionIsRequiredForProfessionalParam0Type) =>
  option.required === ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PRO;

export default roomOptionIsRequiredForProfessional;
