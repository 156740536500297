import { ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_ALL } from "../../../constants/room";

type RoomOptionIsRequiredForAllParam0Type = {
  option: ReturnType<
    typeof import("../../../normalizers/room/parseData").default
  >["optionList"][number];
};

/**
 * Retourne vrai si l'option est requise pour tout le monde.
 */
const roomOptionIsRequiredForAll = ({
  option,
}: RoomOptionIsRequiredForAllParam0Type) =>
  option.required === ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_ALL;

export default roomOptionIsRequiredForAll;
