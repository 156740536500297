import { ROOM_OPTION_STRATEGIES } from "../../../constants/room";

type RoomOptionIsStrategyValidParam0Type = {
  option: ReturnType<
    typeof import("../../../normalizers/room/parseData").default
  >["optionList"][number];
};

/**
 * Retourne vrai si la stratégie est reconnue.
 */
const roomOptionIsStrategyValid = ({
  option,
}: RoomOptionIsStrategyValidParam0Type) =>
  option.strategy in ROOM_OPTION_STRATEGIES;

export default roomOptionIsStrategyValid;
