// constants
import { BOOKING_NATURE_VALUE_PAR } from "@gdf/resources/src/constants/booking";

// helpers
import {
  roomOptionIsRequiredForAll,
  roomOptionIsRequiredForProfessional,
  roomOptionIsRequiredForParticular,
  roomOptionIsNotRequired,
  roomOptionIsStrategyValid,
} from "@gdf/resources/src/helpers/room/option";
import isNatureProfessional from "@gdf/resources/src/helpers/booking/isNatureProfessional";
import isNatureParticular from "@gdf/resources/src/helpers/booking/isNatureParticular";

/**
 * Est-ce que l'option doit-elle s'afficher dans le formulaire de réservation.
 * Cette function retourne vrai dès lors qu'une option
 *   est considérée comme facultative pour un client.
 * Pour qu'elle soit facultative, l'option doit soit
 *   être "non-requise", soit requise pour une nature
 *   "professionnelle" ou "particulière" et
 *   que la nature actuellement sélectionnée est d'une valeur différente.
 */
export default function bookingShouldRenderOption({ option, nature }) {
  if (!nature) {
    nature = BOOKING_NATURE_VALUE_PAR;
  }

  if (!option.isBookable) {
    return false;
  }

  if (isNaN(parseFloat(option.price))) {
    return false;
  }

  if (!roomOptionIsStrategyValid({ option })) {
    // Si la stratégie de l'option n'est pas valide, on n'affiche pas l'option.
    return false;
  }

  if (roomOptionIsRequiredForAll({ option })) {
    // Est-ce que l'option est requise pour tout le monde.
    return false;
  }

  if (roomOptionIsRequiredForProfessional({ option })) {
    // Est-ce que l'option est requise pour les professionnels.
    if (isNatureProfessional({ booking: { nature } })) {
      // Est-ce que la nature de la
      //   réservation est destinée à des professionels.
      return false;
    }

    return true;
  }

  if (roomOptionIsRequiredForParticular({ option })) {
    // Est-ce que l'option est requise pour les particuliers
    if (isNatureParticular({ booking: { nature } })) {
      // Est-ce que la nature de la
      //   réservation est destinée à des particuliers.
      return false;
    }

    return true;
  }

  if (roomOptionIsNotRequired({ option })) {
    // Est-ce que l'option est facultative.
    return true;
  }

  // On ne devrait pas arriver jusque là.
  return true;
}
